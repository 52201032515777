
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { QuickQuestionOptions } from "@/store/types";
import BaseEventComponent from "@/components/BaseEventComponent.vue";
import QuestionReorderingPopup from "@/components/QuestionReorderingPopup.vue";
import draggable from "vuedraggable";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import rg4js from "raygun4js";

interface QuestionAnswer {
  text: string;
  letter: string;
}

@Component({
  components: {
    draggable,
    QuestionReorderingPopup,
  },
})
export default class QuickQuestionComponent extends BaseEventComponent<QuickQuestionOptions> {
  @Ref() videoTag!: HTMLVideoElement;
  @Prop({ default: false }) showReorderWarning!: boolean;

  public question = "";
  public answers: QuestionAnswer[] = [];
  public backgroundStyle = {};
  public isReorderVisible = false;
  public initialOrder: string[] = [];

  private alphabet = Array.from("abcdefghijklmnopqrstuvwxyz");
  private player: videojs.Player | null = null;
  private playerOptions: videojs.PlayerOptions = {
    controls: false,
    aspectRatio: "9:16",
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    },
    html5: {
      vhs: {
        experimentalBufferBasedABR: true,
      },
    },
  };

  @Watch("answers")
  private onAnswersChange(): void {
    this.$matomo && this.$matomo.trackEvent("Question[" + this.stepId + "]", "Reorder", this.answers.map((x) => x.letter).join(","));

    this.$emit("input", this.answers);
  }

  created(): void {
    this.question = this.options.question;

    if (this.value) {
      this.answers = this.value;
    } else {
      this.answers = this.options.choices.map((answer, index) => ({
        text: answer,
        letter: this.alphabet[index],
      }));
    }

    this.backgroundStyle = {
      background: `url("${this.options.backgroundUrl}")`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed !important",
      backgroundPosition: "center",
    };

    this.initialOrder = this.answerLetters;
  }

  mounted(): void {
    this.player = videojs(this.videoTag, this.playerOptions);
    this.player.on("error", this.videoPlayerError.bind(this));
    this.player.src({
      src: this.options.videoUrl,
      type: this.options.videoType,
    });
    this.player.ready(() => {
      this.player?.play();
    });
  }

  beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  videoPlayerError(): void {
    // Media error occurred. Attempt to fix the error after waiting 1000ms.
    const err = this.player?.error();

    // Clear the error from the player
    this.player?.error(null);

    if (err) {
      setTimeout(() => {
        if (this.player) {
          if (err.code === 2) {
            // Reload sources on code 2 "MEDIA_ERR_NETWORK"
            this.player.src("");
            this.player.src({
              src: this.options.videoUrl,
              type: this.options.videoType,
            });
            this.player.load();
          }
          // Try playing again
          this.player.play();
        }
      }, 1000);
    }
  }

  rewatchVideo(): void {
    this.$matomo && this.$matomo.trackEvent("Question[" + this.stepId + "]", "RewatchVideo", this.options.question);
    this.$emit("stepBack");
  }

  backToPrevious(): void {
    this.$matomo && this.$matomo.trackEvent("Question[" + this.stepId + "]", "GoToPreviousQuestion", this.options.question);
    this.$emit("stepBackToLastQuestion");
  }

  cancelPopup(): void {
    this.isReorderVisible = false;
  }

  get answerLetters(): string[] {
    return this.answers.map((x) => x.letter);
  }

  get questionsHaveBeenReordered(): boolean {
    return JSON.stringify(this.initialOrder) === JSON.stringify(this.answerLetters);
  }

  submitAnswers(event: Event, bypassPopup: boolean): void {
    if (this.showReorderWarning && !bypassPopup && this.questionsHaveBeenReordered) {
      this.isReorderVisible = true;
    } else {
      if (!this.answerLetters) {
        rg4js("send", {
          error: "No data provided to ComponentComplete on Quick Question Component",
          customData: [{ stepId: this.stepId, question: this.question }],
        });
      }
      this.markComplete(this.answerLetters);
    }
  }
}
