
import { TypedVue, AssessmentState, AssessmentSchemaEvent, AssessmentStatus } from "@/store/types";
import { Actions } from "@/store/assessment/actions";
import { Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import VideoComponent from "@/components/VideoComponent.vue";
import QuickQuestionComponent from "@/components/QuickQuestionComponent.vue";
import QuestionReorderingPopup from "@/components/QuestionReorderingPopup.vue";
import Vue from "vue";
import rg4js from "raygun4js";

const namespace = "assessment";

const components: { [key: string]: typeof Vue } = {
  VideoComponent,
  QuickQuestionComponent,
  QuestionReorderingPopup,
};

@Component({
  components,
})
export default class Agreements extends TypedVue {
  @Action(Actions.UPDATE_ASSESSMENT_ANSWER, { namespace })
  updateAssessmentAnswer!: (data: { questionId: string; questionAnswer: any }) => Promise<void>;

  @Action(Actions.START_ASSESMENT_ATTEMPT, { namespace })
  startAssessmentAttempt!: (lang: string) => Promise<void>;

  @Action(Actions.UPDATE_ASSESMENT_DATA, { namespace })
  updateAssessmentData!: (data: AssessmentState) => Promise<void>;

  public schemaLoaded = false;
  public loadComponent = true;
  public questionData: { [id: string]: any } = {};
  private currentEventIndex = 0;
  private steppingBack = false;
  private lastViewedComponentIndex = 0;

  get currentComponent(): typeof Vue {
    return components[`${this.currentEventInfo.type}Component`];
  }

  get currentComponentOptions() {
    const options = {
      ...this.currentEventInfo.options,
      hasPreviousQuestion: !!this.previousQuestion,
      isCurrentComponent: this.lastViewedComponentIndex === this.currentEventIndex,
    };

    if (this.steppingBack) {
      this.steppingBack = false;
      return { ...options, steppingBack: true };
    }

    return options;
  }

  get schemaEvents(): AssessmentSchemaEvent<any>[] {
    if (!this.$store.state.assessment.assessmentSchema) {
      throw new Error("schema not loaded.");
    }

    return this.$store.state.assessment.assessmentSchema.schema.events;
  }

  get isFirstQuickQuestion(): boolean {
    return this.currentEventInfo === (this.schemaEvents.filter((x) => x.type === "QuickQuestion") || [null])[0];
  }

  get currentEventInfo() {
    return this.schemaEvents[this.currentEventIndex];
  }

  get totalEventCount() {
    return this.schemaEvents.length;
  }

  get previousQuestion() {
    let currentStep = this.currentEventIndex;

    while (currentStep > 0) {
      currentStep--;

      if (this.schemaEvents[currentStep].type === "QuickQuestion") {
        return this.schemaEvents[currentStep];
      }
    }

    return null;
  }

  async created(): Promise<void> {
    const token = this.$store.state.assessment.assessmentToken;

    if (token) {
      await this.startAssessmentAttempt((this.$route.query.lang as string) ?? "en");

      if (this.$store.state.assessment.assessmentStatus !== AssessmentStatus.ACTIVE) {
        console.error("Assessment status not active.");
        await this.$router.push({
          path: "/",
          query: { t: token },
        });
      }

      const previousAnswerKeys = Object.keys(this.$store.state.assessment.assessmentState?.questionResponses ?? {});

      if (previousAnswerKeys.length > 0 && this.$store.state.assessment.assessmentSchema) {
        // Resume from existing answers
        const lastAnsweredQuestion = previousAnswerKeys[previousAnswerKeys.length - 1];
        const previousSchemaStep = this.$store.state.assessment.assessmentSchema.schema.events.find((x) => x.id === lastAnsweredQuestion);

        if (previousSchemaStep) {
          this.currentEventIndex = Math.max(this.$store.state.assessment.assessmentSchema.schema.events.indexOf(previousSchemaStep) + 1, 0);
        } else {
          console.error("Unable to find the last answered question in the schema");
        }
      }

      this.schemaLoaded = true;
    } else {
      console.error("Assessment token not provided.");
      await this.$router.push({ path: "/", query: { t: token } });
    }
  }

  async stepBackToLastQuestion(data: any) {
    this.currentEventIndex = this.previousQuestion ? this.schemaEvents.indexOf(this.previousQuestion) : 0;
    this.triggerNextComponentLoad();
  }

  async stepBack(data: any) {
    this.steppingBack = true;
    this.currentEventIndex = Math.max(this.currentEventIndex - 1, 0);
    this.triggerNextComponentLoad();
  }

  async stepForward(data: any) {
    this.currentEventIndex = Math.min(this.currentEventIndex + 1, this.totalEventCount - 1);

    this.triggerNextComponentLoad();
  }

  triggerNextComponentLoad() {
    // This forces the components to reload to solve the
    // problem with 2 of the same types not loading
    this.loadComponent = false;
    this.$nextTick(() => {
      this.loadComponent = true;
    });
  }

  async componentComplete(data: any) {
    if (data) {
      await this.updateAssessmentAnswer({
        questionId: this.currentEventInfo.id,
        questionAnswer: data,
      });
    }

    if (this.currentEventIndex === this.totalEventCount - 1) {
      await this.updateAssessmentData({ isComplete: true, language: (this.$route.query.lang as string) ?? "en" });
      await this.$router.push({
        path: "complete",
        query: { lang: this.$route.query.lang, subtitles: this.$route.query.subtitles },
      });
    } else {
      this.currentEventIndex++;
    }

    this.lastViewedComponentIndex = Math.max(this.lastViewedComponentIndex, this.currentEventIndex);
    this.triggerNextComponentLoad();
  }
}
